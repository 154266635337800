import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home.vue'
export const Layout = () => import("@/layout/index.vue");
export const LayoutAI = () => import("@/layout/index_content.vue");

const routeList: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/home.vue"),
        name: "home",
        meta: { title: "home", requireAuth: true },
      },
    ],
  },
  {
    path: "/about",
    component: Layout,
    children: [
      {
        path: "/about",
        component: () => import("@/views/about.vue"),
        name: "about",
        meta: { title: "about", requireAuth: true },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: 'login'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register.vue'),
    meta: {
      title: 'register'
    }
  },
  {
    path: '/reportupload',
    name: 'reportupload',
    component: () => import('@/views/reportupload.vue'),
    meta: {
      title: 'reportupload'
    }
  },
]


// 静态路由
export const aiRoutes: RouteRecordRaw[] = [
  {
    path: "/ai",
    component: LayoutAI,
    redirect: "/ai/text",
    children: [
      {
        path: "/ai/chat",
        component: () => import("@/views/chat.vue"),
        name: "chat",
        meta: { title: "chat", requireAuth: true },
      },
      {
        path: "/ai/text",
        component: () => import("@/views/text.vue"),
        name: "text",
        meta: { title: "text", requireAuth: true },
      },
      {
        path: "/ai/data",
        component: () => import("@/views/data.vue"),
        name: "data",
        meta: { title: "data", requireAuth: true },
      },
      {
        path: "/ai/trans",
        component: () => import("@/views/trans.vue"),
        name: "trans",
        meta: { title: "trans", requireAuth: true },
      },
    ],
  },
];

let routes = [...routeList, ...aiRoutes]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

/**
 * 重置路由
 */
export function resetRouter() {
  router.replace({ path: "/login" });
}

export default router
